<template>
  <div class="base-class">
    <div class="base-content">
      <div class="content">
        <div class="header-title">
          <span >主修车型</span>
          <span class="select-content-color">（可多选）</span>
        </div>
        <div class="main-content">
          <div v-for="item in mainCarType" :key="item.id" class="main-div">
            <div class="c-m" @click="selectMainCarType(item)">
              <img v-show="item.check" class="select-img" src="@/assets/images/garage/select.png">
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="header-title">
          <span >业务范围</span>
          <span class="select-content-color">（可多选）</span>
        </div>
        <div class="main-content">
          <div v-for="item in businessType" :key="item.id" class="main-div">
            <div class="c-m" @click="selectBusinessType(item)">
              <img v-show="item.check" class="select-img" src="@/assets/images/garage/select.png">
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" style="padding-bottom: 0px;">
        <div class="foot-title">
          <div class="title-msg">是否有烤漆车间</div>
          <div :class="[checkType ? 'select-title-right': 'no-normal-title', 'normal-title']" @click="selectType">没有</div>
          <div :class="[!checkType ? 'select-title-right ': 'no-normal-title', 'title-lf normal-title']" @click="selectType">有</div>
        </div>
        <div class="foot-title" style="display: flex; border-bottom: none" v-if="!checkType">
          <div class="title-msg title-msg-w">钣喷工位数</div>
          <cube-input v-model="paintStationCount" class="foot-content-input" placeholder="请输入工位数量" :maxlength="6"></cube-input>
          <div class="msg">个</div>
        </div>
      </div>
    </div>

    <div class="foot-content">
      <cube-button class="foot-content-button" type="submit" @click="submitted">确认提交</cube-button>
    </div>

  </div>
</template>

<script>
import { getCarMajorModelList, getBusinessList } from 'api/item'
import { garagePerfectGarageDetail } from 'api/user'
export default {
  data () {
    return {
      checkType: false,
      mainCarType: [],
      businessType: [],
      paintStationCount: '',
      params: {}
    }
  },
  mounted () {
    this.getMianCarList()
    this.getBusinessList()
  },
  methods: {
    // 获取业务范围
    getBusinessList () {
      getBusinessList().then(res => {
        console.log('==========res========', res)
        const data = res.data
        if (data && data.result === 0) {
          const result = data.data
          if (Array.isArray(result)) {
            this.businessType = result.map(ele => {
              return {
                id: ele.id,
                name: ele.name,
                check: false
              }
            })
          }
        }
      })
    },
    // 获取主营车型
    getMianCarList () {
      getCarMajorModelList().then(res => {
        console.log('==========res========', res)
        const data = res.data
        if (data && data.result === 0) {
          const result = data.data
          if (Array.isArray(result)) {
            this.mainCarType = result.map(ele => {
              return {
                id: ele.id,
                name: ele.name,
                check: false
              }
            })
          }
        }
      })
    },
    // 主营车型选择
    selectMainCarType (value) {
      const selectCode = value.id
      this.mainCarType = this.mainCarType.map(ele => {
        if (ele.id === selectCode) {
          ele.check = !ele.check
        }
        return ele
      })
    },
    // 业务范围选择
    selectBusinessType (value) {
      const selectCode = value.id
      this.businessType = this.businessType.map(ele => {
        if (ele.id === selectCode) {
          ele.check = !ele.check
        }
        return ele
      })
    },
    // 是都有烤漆车间选择
    selectType () {
      this.checkType = !this.checkType
      this.paintStationCount = ''
    },
    getParams () {
      const businessList = this.businessType.filter(ele => ele.check)
      const modelList = this.mainCarType.filter(ele => ele.check)
      this.params = {
        businessList: businessList,
        modelList: modelList,
        paintShop: 1,
        paintStationCount: this.paintStationCount
      }
      if (this.checkType) {
        this.params.paintShop = 0
      }
      if (!Array.isArray(modelList) || modelList.length === 0) return this.getToast('请选择主修车型')
      if (!Array.isArray(businessList) || businessList.length === 0) return this.getToast('请选择业务范围')
      if (!this.checkType && this.paintStationCount === '') return this.getToast('请输入钣喷工位数')
      if (this.paintStationCount !== '' && !/^[0-9]*$/.test(this.paintStationCount)) return this.getToast('钣喷工位数只支持输入6位下的数字')
      console.log('===完善信息的确认提交按钮====params', this.params)
      return true
    },
    submitted () {
      if (!this.getParams()) return
      console.log('===完善信息的确认提交按钮====', this.params)
      garagePerfectGarageDetail(this.params).then(res => {
        console.log('====完善信息的确认======:', res)
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.$router.push({
          name: '注册成功'
        })
      })
    },
    getToast (msg) {
      const toast = this.$createToast({
        time: 1000,
        txt: msg,
        type: 'warn'
      })
      toast.show()
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.base-class{
  width:100%;
  height: 100vh;
  background:rgba(240,240,240,1);
}
.base-content{
  padding-left: px2rem(30px);
  background:rgba(255,255,255,1);
}
.content{
  border-bottom: px2rem(1px) solid rgba(228,228,228,1);;
  padding-bottom: px2rem(30px);
  .header-title{
    padding-top: px2rem(27px);
    height:px2rem(40px);
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(54,54,54,1);
    line-height:px2rem(40px);
  }
  .select-content-color{
    color:rgba(153,153,153,1);
  }
  .main-content{
    .main-div{
      display: inline-block;
      margin-top: px2rem(10px);
    }
    .c-m{
      width: px2rem(210px);
      height: px2rem(70px);
      background:rgba(245,245,245,1);
      border-radius:px2rem(4px);
      display: inline-block;
      margin-right: px2rem(30px);
      font-size:px2rem(24px);
      font-family:PingFangSC-Regular;
      font-weight:400;
      color:rgba(54,54,54,1);
      line-height: px2rem(70px);
      text-align: center;
    }
    .select-img{
      width: px2rem(36px);
      height: px2rem(38px);
      position: absolute;
      margin-left: px2rem(66px);
    }
  }
  .foot-title{
    height: px2rem(90px);
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    padding-right: px2rem(30px);
    color:rgba(54,54,54,1);
    border-bottom: px2rem(1px) solid rgba(228,228,228,1);
    .title-msg{
      height: px2rem(91px);
      line-height:px2rem(91px);
      width:px2rem(210px);
      display: inline-block
    }
    .title-msg-w{
      width: px2rem(160px);
      margin-right: px2rem(32px);
    }
    .no-normal-title{
      background: white;
      border:px2rem(1px) solid rgba(204,204,204,1);
    }
    .normal-title{
      float: right;
      width:px2rem(103px);
      height:px2rem(60px);
      line-height: px2rem(60px);
      text-align: center;
      margin-top: px2rem(15px);
    }
    .select-title-right{
      background:rgba(242,248,255,1);
      border:px2rem(1px) solid rgba(64,129,214,1);
    }
    .title-lf{
      margin-right: px2rem(20px);
    }
    .foot-content-input{
      width: px2rem(496px);
    }
    .cube-input::after{
      border: none;
    }
    .msg{
      line-height: px2rem(88px);
      font-size:px2rem(28px);;
      font-family:PingFangSC-Regular;
      font-weight:400;
      color:rgba(153,153,153,1);
    }
  }
}
.foot-content{
  margin-top: px2rem(60px);
  padding-right: px2rem(30px);
  padding-left: px2rem(30px);
  padding-bottom: px2rem(60px);
  .foot-content-button{
    height: px2rem(90px);;
  }
}
</style>
